import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import FanGoBody from '../components/common/body/FanGoBody';
import { FanGoTextProcessor } from '../components/common/portableText';
import StandardSEO from '../components/common/seo/StandardSEO';
import FanGoTitle from '../components/common/texts/FanGoTitle';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import BlogsPreview from '../components/PageComps/CommonComps/blogsPreview/BlogsPreview';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import ReverseImageAndText from '../components/PageComps/CommonComps/ReverseImageAndText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgContainer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 80,
      },
    },
    text: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  })
);

const TablePlansPage = ({ data }) => {
  const classes = useStyles();
  const { sanityTablePlansPage } = data;
  const { pageHead } = sanityTablePlansPage;
  const { title, _rawSubTitle: subTitle, background } = pageHead;
  const { asset, altText } = background;
  const { paragraphOne, paragraphTwo, whyChooseFanGoTablePlanDesigner } =
    sanityTablePlansPage;
  const { _rawBody: paragraphOneRawBody } = paragraphOne;
  const { _rawBody: paragraphTwoRawBody, title: paragraphTwoTitle } =
    paragraphTwo;
  const { title: whyChooseFanGoTablePlanDesignerTitle, imagesAndParagraphs } =
    whyChooseFanGoTablePlanDesigner;

  const smallScreen = useMediaQuery(`(max-width: 900px)`);

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="0% 50%"
        imageData={asset.gatsbyImageData}
        shadow="0.55"
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            titleRowSpacing={30}
            titlePaddingBottom={undefined}
            largeSubTitleSize
          />
        }
      />
      <FanGoBody paddingTop="20px" paddingBottom="20px">
        <FanGoTextProcessor text={paragraphOneRawBody} textAlign={'center'} />
      </FanGoBody>
      <FanGoBody paddingTop="20px" paddingBottom="20px">
        <FanGoTitle variant="h3" title={whyChooseFanGoTablePlanDesignerTitle} />
        <ReverseImageAndText
          imagesAndParagraphs={imagesAndParagraphs}
          smallScreen={smallScreen}
        />
      </FanGoBody>
      <div
        style={{
          paddingTop: '30px',
          paddingBottom: '30px',
          background: `linear-gradient(10deg, #2d348a 40%, #141855 90%)`,
        }}
      >
        <div style={{ maxWidth: '80%', margin: 'auto' }}>
          <FanGoTitle variant="h3" title={paragraphTwoTitle} color="white" />
          <FanGoTextProcessor
            text={paragraphTwoRawBody}
            textAlign="center"
            color="white"
          />
        </div>
      </div>
      <FanGoBody paddingTop="0px" paddingBottom="20px">
        <TrustedBrandIcons />
      </FanGoBody>
      <BlogsPreview
        data={{
          ...data.sanityHomePage.blogsPreview,
          blogs: data.allSanityPost.nodes,
        }}
      />
    </>
  );
};

export default TablePlansPage;
export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityTablePlansPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityTablePlansPage {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        _rawSubTitle
        background {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
          }
        }
      }
      paragraphOne {
        title
        _rawBody
      }
      paragraphTwo {
        title
        _rawBody
      }
      whyChooseFanGoTablePlanDesigner {
        title
        imagesAndParagraphs {
          image {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                height: 600
                formats: [WEBP]
              )
            }
          }
          paragraphs {
            text
            title
          }
        }
      }
    }
    sanityHomePage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
